import Link from 'next/link'
import { filterSubNavLinkData } from 'library/filters/navigation'

const SubscriptionList = ({ subscriptions }) => {

  // List items
  const items = []

  /**
   * NOTE:
   * Normally the subscriptions prop will always be an array of subscription entries from Contentful
   * However, for the Exclusives tab we occassionally pass through custom external links
   */

  // Bulid list
  for (const item of subscriptions) {

    if (item.itemNumber === 'PP2') {
      items.push(
        <li key={item.cfId}>
          <Link href={'/subscription/pp2'}>
            <div className="pp2-nav-link">
              <img
                src="/images/logo-pp2.png"
                srcSet="/images/logo-pp2.png 1x, /images/logo-pp2@2x.png 2x"
                alt="Project Prophecy 2.0"
                title="Project Prophecy 2.0"
              />
              <div>
                {item.customFields?.menuPrefix && <>{item.customFields.menuPrefix}<br /></>}
                <span>{item.title}</span>
              </div>
            </div>
          </Link>
        </li>
      )
      continue
    }

    // Build links by entry type
    switch (item.cfType) {

      // Custom links
      case 'navigationItem':

        if (item.customUrl) {
          items.push(
            <li key={item.cfId}>
              <a href={item.customUrl} target="_blank">{item.name}</a>
            </li>
          )
        }
        break

      // Subscription is always default
      default:
        let type = 'subscription'
        if (item.type && item.type === 'Membership') type = 'membership'
        const linkHref = filterSubNavLinkData(`/${type}/${item.itemNumber.toLowerCase()}`, item)
        items.push(
          <li key={item.cfId}>
            <Link href={linkHref}>
              {item.title}
            </Link>
          </li>
        )
        break
    }
  }

  return (
    <ul className="subscription-list no-style">
      {items}
    </ul>
  )
}

export default SubscriptionList
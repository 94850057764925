import { useEffect, useContext } from 'react'
import { SiteContext } from 'library/context/siteContext'
import { UserContext } from 'library/context/userContext'
import Router from 'next/router'
import Link from 'next/link'

import SubscriptionCard from 'components/structure/subscription-card'

const SubscriptionMenu = ({ visible = false }) => {

  // Set contexts
  const { userState, userHasAccess } = useContext(UserContext)
  const { fulfillmentProduct, customSet } = useContext(SiteContext)

  // Set vars
  let gurus = []
  const guruIds = process.env.NEXT_PUBLIC_GURU_IDS.split(',')
  const fullAccessUsers = process.env.NEXT_PUBLIC_FULL_ACCESS_IDS ? process.env.NEXT_PUBLIC_FULL_ACCESS_IDS.split(',') : []
  const showFullList = fullAccessUsers.includes(userState.account.customerNumber) ? true : false

  // Get all of the gurus data by subscription
  if (fulfillmentProduct && fulfillmentProduct.length > 0) {
    for (const item of fulfillmentProduct) {
      if (item.cfType === 'subscription') {
        if (item.author && guruIds.includes(item.author.cfId)) {
          const found = gurus.find(author => author.cfId === item.author.cfId)
          if (!found) {
            item.author.order = guruIds.indexOf(item.author.cfId)
            item.author.activeSubs = []
            item.author.inactiveSubs = []
            gurus = [ ...gurus, item.author ]
          }
        }
      }
    }
  }

  // Now organize gurus in priority order
  const sortedGurus = [ ...gurus ]
    sortedGurus.sort(function(a, b) {
      const itemA = a.order
      const itemB = b.order
      if (itemA < itemB) return -1
      if (itemA > itemB) return 1
      return 0
    })

  // Assign pubs by guru and user access
  const activeSubs = [] 
  const inactiveSubs = []
  for (const guru of sortedGurus) {
    const subscriptions = fulfillmentProduct.filter(item => {
      if (item.cfType === 'subscription' && item.author && item.author.cfId === guru.cfId) {
        return item
      }
    })

    // Check if user has access
    for (const sub of subscriptions) {

      // Skip hidden pubs
      if (sub.hide) continue

      // If user has PLA, don't show PLA_GOLD
      if (userHasAccess('PLA_GOLD', ['subscriptionView']) && sub.itemNumber === 'PLA' && !showFullList) continue

      // Organize into active/inactive
      if (userHasAccess(sub)) {
        guru.activeSubs = [ ...guru.activeSubs, sub ]
      } else {

        // Don't show folded, but visible pubs
        if (sub.folded) continue

        // Don't show PP2 in inactive
        if (sub.itemNumber === 'PP2') continue

        guru.inactiveSubs = [ ...guru.inactiveSubs, sub ]
      }
    }

    // Append component
    if (guru.activeSubs.length > 0) activeSubs.push(<SubscriptionCard key={`active-${guru.displayName}`} author={guru} active />)
    if (guru.inactiveSubs.length > 0) inactiveSubs.push(<SubscriptionCard key={`active-${guru.displayName}`} author={guru} />)

  }

  // Add custom nav sections for Paradigm Exclusives
  let paradigmExclusives
  if (customSet) {
    const customNavSet = customSet.find(item => item.slug === 'paradigm-exclusives')
    if (customNavSet) {
      const customActiveSubs = []
      if (customNavSet.item) {
        for (const item of customNavSet.item) {

          // Custom navigation
          if (item.cfType === 'navigationItem') {
            customActiveSubs.push(item)
          } else {

            // Fulfillment
            if (userHasAccess(item)) {
              customActiveSubs.push(item)
            }

          }
        }
      }
      if (customActiveSubs.length > 0) {
        paradigmExclusives = {
          displayName: customNavSet.title,
          cfId: customNavSet.cfId,
          activeSubs: customActiveSubs
        }
      }
    }
  }
  if (paradigmExclusives) {
    activeSubs.push(<SubscriptionCard key={`active-${paradigmExclusives.displayName}`} author={paradigmExclusives} active />)
  }

  // Built HTML for active subs
  let activeDisplay
  if (activeSubs.length > 0) {
    activeDisplay = (
      <div className="subscription-menu subscription-menu--active">
        <div className="grid-x grid-margin-x grid-margin-y">
          {activeSubs}
        </div>
      </div>
    )
  }

  // Built HTML for inactive subs
  let inactiveDisplay
  if (inactiveSubs.length > 0) {
    inactiveDisplay = (
      <div className="subscription-menu subscription-menu--inactive">
        <div className="subscription-menu__header">You Might Also Be Interested In...</div>
        <div className="grid-x grid-margin-x grid-margin-y">
          {inactiveSubs}
        </div>
      </div>
    )
  }

  // Show OWC membership
  let owcMember
  if (userState.membership && userState.membership === 'OWC') {
    owcMember = (
      <div className="subscription-menu__membership subscription-menu__membership--owc">
        <Link href="/membership/owc">
          <strong>Ω</strong> <em>Omega Wealth Circle Membership</em>
        </Link>      
      </div>
    )
  }

  return (
    <div className="subscription-menu__container">
      <div className="grid-container">
        <div className={`subscription-menu__wrapper ${visible ? 'open' : ''}`}>
          {owcMember && (
            <div className="subscription-menu__memberships">
              {owcMember}
            </div>
           )}
          {activeDisplay}
          {userState.membership && userState.membership === 'OWC' ? null : inactiveDisplay}
        </div>
      </div>
    </div>
  )
}

export default SubscriptionMenu